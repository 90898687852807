import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { AppsDownload } from "../../components";
import "./styles.scss";

class Footer extends Component {
  render() {
    return (
      <div className="footer" id="footer" style={{ height: window.innerWidth < 900 ? "unset" : "31vh" }}>
        <div className="container">
          <div className="row">
            <div className="col col-1" style={{ textAlign: window.innerWidth < 900 ? "left" : "center" }}>
              <div>
                <div className="logo">
                  <NavLink to="/">
                    <img src="/amarant-logo.svg" alt="Амарант България" />
                  </NavLink>
                </div>
                <AppsDownload />
              </div>
            </div>
            <div className="col col-2">
              <div>
                <ul className="nav-footer">
                  <li>
                    <NavLink activeClassName="active" to="/subscriptions">
                      Абонаменти
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to="/blog">
                      Новини
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to="/offices">
                      Офиси
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to="/documents">
                      Документи
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-3">
              <div>
                <h4>Свържете се с нас</h4>
                <p>
                  <span className="icon">
                    <img src="../imgs/email.png" alt="мейл" />
                  </span>
                  office@amarantbg.com
                </p>
                <p className="p-phones">
                  <span className="icon">
                    <img src="../imgs/phone.png" alt="телефон" />
                  </span>
                  <span>02/492 13 53</span>
                  {window.innerWidth < 900 ? <span>02/492 13 55</span> : <p style={{ marginLeft: "37px", marginTop: "23px" }}>02/492 13 55</p>}
                </p>
              </div>
            </div>
            <div
              className="col col-4"
              style={{
                marginLeft: window.innerWidth < 900 ? "0%" : "5%",
                marginBottom: window.innerWidth < 900 && "2%",
              }}
            >
              <h4>Последвайте ни</h4>
              <div
                style={{ display: "flex", cursor: "pointer", alignItems: "center", paddingBottom: "25px" }}
                onClick={() => {
                  window.open("https://instagram.com/amarant_bg?igshid=pxsosxz9ysj3");
                }}
              >
                <span className="icon" style={{ marginRight: window.innerWidth > 760 && window.innerWidth < 900 ? "2%" : "3%", width: "25px" }}>
                  <img src="../imgs/instagram.png" alt="instagram" />
                </span>
                amarant_bg
              </div>
              <div
                className="p-phones"
                style={{ display: "flex", cursor: "pointer", alignItems: "center", paddingBottom: "25px" }}
                onClick={() => {
                  window.open("https://www.facebook.com/amarantbg/");
                }}
              >
                <span className="icon" style={{ marginRight: window.innerWidth > 760 && window.innerWidth < 900 ? "2%" : "3%", width: "25px" }}>
                  <img src="../imgs/facebook.png" alt="facebook" />
                </span>
                amarantbg
              </div>
              <div
                className="p-phones"
                style={{ display: "flex", cursor: "pointer", alignItems: "center", paddingBottom: "25px" }}
                onClick={() => {
                  window.open("https://www.youtube.com/channel/UCUbldQNeW_udMb0vGgyGe3w");
                }}
              >
                <span className="icon" style={{ marginRight: window.innerWidth > 760 && window.innerWidth < 900 ? "2%" : "3%", width: "25px", marginTop: window.innerWidth < 900 && window.innerWidth > 760 ? "0.5%" : "1%" }}>
                  <img src="../imgs/youtube.png" alt="youtube" />
                </span>
                Amarant Bulgaria
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(Footer);
