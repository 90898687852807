import React, { useEffect } from "react";
import { Section } from "../../components";
import "./styles.scss";

const textArray = [
  {
    title: "Автоматична проверка пътни глоби",
    firstLine: "Приложението прави автоматично и ежедневно проверка за налични глоби към КАТ за всички водачи, присъстващи във Вашия профил. При наличие на нова глоба, веднага ще бъдете известени с цел възможност да се възползвате от държавната отстъпка в размер на 30% за ранно заплащане.",
  },
  {
    title: "Видеорегистратор",
    firstLine: "Трансформирайте Вашия телефон във видеорегистратор и не пропускайте момент от обстановката по пътищата. ",
    secondLine: "Когато станете свидетел на събитие, което искате да бъде записано, натиснете червения бутон и той ще запази като видео последните 60 секунди. Съхранявайте видеата директно на облак без да товарите устройството и изпращайте сигнали директно към КАТ.",
  },
  {
    title: "Завеждане на щети",
    firstLine: "Завеждайте щети директно през Вашия мобилен телефон в няколко стъпки. Приложението събира информацията относно настъпилото събитие и съставя двустранен констативен протокол при необходимост. След това се изпращат уведомление, документи за събитието и претенция за обезщетение към застрахователя.",
    secondLine: "Тази функционалност е за всички със закупена полица Автокаско от АРМЕЕЦ, БУЛИНС и ДЗИ.",
  },
];

const secondTextArray = [
  {
    title: "Неограничен брой автомобили",
    firstLine: "Добавете и управлявайте в профила си неограничен брой автомобили. Към всяка кола имате актуална справка за налични застраховки, ГТП, винетка и възможност за управление директно през Амарант.",
    secondLine: "Ако споделяте автомобил с друг потребител на Амарант, то информацията бива актуализирана в реално време между всички потребители с този автомобил - колегата купува винетка за служебния автомобил, а Вие веднага виждате валидността ѝ и справка за разходите по нея.",
  },
  {
    title: "Неограничен брой водачи",
    firstLine: "Без ограничение на броя шофьорски книжки, които можете да съхранявате във Вашия профил и съответно за които да бъдете известявани при наличие на глоби.",
    secondLine: "Съхранението на дадена шофьорска книжка също така Ви позволява да купувате нови застраховки, да завеждате щети и други удобни услуги, предлагани в платформата. Също така Амарант следи за валидността на всички книжки и ще Ви извести, когато срокът за подновяване наближи.",
  },
  {
    title: "Приоритетна техническа помощ",
    firstLine: "Нашите експерти ще са на линия за Вас под формата на чат и Вашите теми ще бъдат разглеждани винаги с приоритет.",
    secondLine: "Независимо от казуса, ние ще Ви съдействаме в най-кратки срокове.",
  },
];

const Insurances = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div
        className="first-section"
        style={{
          height: window.innerWidth < 900 && window.innerWidth > 760 && "65vh",
        }}
      >
        <h1
          style={{
            textTransform: "uppercase",
            color: "white",
            fontSize: window.innerWidth < 900 ? "24px" : "27px",
            marginTop: window.innerWidth < 900 ? "7%" : "3%",
            width: window.innerWidth < 900 ? "99%" : "50%",
            textAlign: "center",
            marginBottom: "5%",
          }}
        >
          Получавате достъп до ексклузивни функционалности при заплащане на малка месечна/годишна сума
        </h1>
        <div
          className="first-section-img"
          style={{
            height: window.innerWidth < 900 && window.innerWidth > 760 && "70%",
          }}
        />
      </div>

      {textArray.map((section, i) => {
        return (
          <Section
            image={`url(${require(`../../assets/imgs/subscription-section-${i + 2}.png`)})`}
            right={window.innerWidth < 900 ? false : i === 0 || i === 2 || (i === 4 && true)}
            reversed
            height={window.innerWidth < 900 ? 115 : 102}
            title={section.title}
            maxHeight={window.innerWidth < 900 && window.innerWidth > 760 ? 30 : window.innerWidth < 900 && window.innerWidth < 760 && 45}
            renderContent={() => {
              return (
                <>
                  <p className="info-text">{section.firstLine}</p>
                  <p className="info-text">{section.secondLine}</p>
                </>
              );
            }}
          />
        );
      })}

      {secondTextArray.map((section, i) => {
        return (
          <Section
            image={`url(${require(`../../assets/imgs/subscription-section-${i + 5}.png`)})`}
            right={window.innerWidth < 900 ? false : i === 1 || i === 3 || (i === 4 && true)}
            reversed
            height={window.innerWidth < 900 ? (i === 2 ? 110 : 120) : 102}
            title={section.title}
            maxHeight={window.innerWidth < 900 && window.innerWidth > 760 ? 30 : window.innerWidth < 900 && window.innerWidth < 760 ? (i === 2 ? 40 : 50) : "unset"}
            renderContent={() => {
              return (
                <>
                  <p className="info-text">{section.firstLine}</p>
                  <p className="info-text">{section.secondLine}</p>
                </>
              );
            }}
          />
        );
      })}

      <Section
        image={`url(${require(`../../assets/imgs/subscription-section-8.png`)})`}
        right={window.innerWidth < 900 ? false : true}
        reversed
        height={window.innerWidth < 900 && window.innerWidth > 760 ? 130 : 100}
        innerML={window.innerWidth < 900 && 3}
        titleStyle={{ marginTop: "5%" }}
        title={"Не се колебайте и станете специален  потребител"}
        maxHeight={window.innerWidth < 900 && window.innerWidth > 760 && 40}
        renderContent={() => {
          return (
            <div style={{ paddingBottom: "30px" }}>
              <div className="special-user-tab">
                <h3 style={{ fontSize: "19px" }}>Месечен</h3>
                <p style={{ marginRight: "3%" }}>
                  <b style={{ color: "#1F55E6" }}>4.99лв./месец</b>
                </p>
              </div>
              <div className="special-user-tab" style={{ marginTop: "5%" }}>
                <h3 style={{ fontSize: "19px" }}>Годишен</h3>
                <p style={{ marginRight: "3%" }}>
                  <b style={{ color: "#1F55E6" }}>49.99лв</b>
                </p>
              </div>
              <div className="add-info-tab">
                <p className="info-text">Възползвайте се от 2 месеца безплатно, ако активирате годишен абонаментен план</p>
              </div>
            </div>
          );
        }}
      />
    </>
  );
};

export default Insurances;
