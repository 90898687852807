import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB2WH7oh38LsOEStg48cJa9lTv_kDRN1Ts",
  authDomain: "amarant-app.firebaseapp.com",
  databaseURL: "https://amarant-app.firebaseio.com",
  projectId: "amarant-app",
  storageBucket: "amarant-app.firebasestorage.app",
  messagingSenderId: "1083851755409",
  appId: "1:1083851755409:web:10c28358062ec76c525430",
  measurementId: "G-BD0XFMDRRR"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics }
