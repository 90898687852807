import { nanoid } from "nanoid";

export const getPresignedUrlView = async (fileUrl) => {
  const response = await fetch(`https://amarant.herokuapp.com/cloudServices/s3downloadBA/${nanoid()}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + window.btoa("S3Downloader!:e!J@26VLJdvy%*os%84BbD523212"),
    },
    body: JSON.stringify({ url: fileUrl }),
  });
  if (!response.ok) throw new Error("Грешка");
  const { payload } = await response.json();
  return payload?.presignedUrl;
};
