import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import "./styles.scss";

const DownloadApp = () => {
  useEffect(() => {
    document.getElementById("header").style.display = "none";
    document.getElementById("body").style.overflowX = "hidden";
    document.getElementById("footer").style.display = "none";
  }, []);
  return (
    <div className="download-app">
      <NavLink
        to="/"
        onClick={() => {
          document.getElementById("header").style.display = "block";
          document.getElementById("footer").style.display = "block";
        }}
      >
        {" "}
        <div className="amarant-logo" />
      </NavLink>
      <div className="blue-container">
        <div className="left-side">
          <div>
            <h1 style={{ textTransform: "uppercase", fontSize: "26px" }}>
              Линкът е достъпен през мобилен телефон.
            </h1>
            <p style={{ fontSize: "17px",marginBottom:"70px" }}>
              Моля отворете линка, използвайки Вашия мобилен телефон. Така ще
              можете да достъпите конкретната информация в мобилното приложение
              Амарант.
            </p>
          </div>
          <div className="download-section">
            <div className="left-download-section">
              <p style={{ fontSize: "17px" }}>Свалете от:</p>
              <div
                className="download-icon app-store"
                onClick={() => {
                  window.open(
                    "https://apps.apple.com/us/app/amarant-bulgaria/id1506724182"
                  );
                }}
              />
              <div
                className="download-icon google-play"
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.amarantapp&hl"
                  );
                }}
              />
            </div>
            <div
              className="left-download-section"
              style={{ marginLeft: "5%", borderRight: "none" }}
            >
              <p style={{ fontSize: "17px" }}>Сканирайте кода:</p>
              <div className="download-icon qr" />
            </div>
          </div>
        </div>
        <div className="right-side-image" />
      </div>
      <div className="social-media-container">
        <div className="social-media facebook" onClick={() => {window.open("https://www.facebook.com/amarantbg/")}}/>
        <div className="social-media instagram" onClick={() => {window.open("https://instagram.com/amarant_bg?igshid=pxsosxz9ysj3")}} />
        <div className="social-media youtube" onClick={() => {window.open("https://www.youtube.com/channel/UCUbldQNeW_udMb0vGgyGe3w")}}/>
      </div>
    </div>
  );
};

export default connect()(DownloadApp);
