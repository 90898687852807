import React from "react";
import { useEffect, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { Link } from "react-router-dom";
import "./styles.scss";
import { getPresignedUrlView } from "../../utilities/files";

export default function NewsComponent({ blogPageWithSize, getBlogPageWithSize, nextPage }) {
  const [presignedUrls, setPresignedUrls] = useState({}); // Store URLs for each blog

  const callbackFunction = () => {
    getBlogPageWithSize({
      page: nextPage,
      size: 10,
    });
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  useBottomScrollListener(callbackFunction);

  useEffect(() => {
    let isMounted = true; // Prevent memory leaks

    const fetchPresignedUrls = async () => {
      const newUrls = {};
      await Promise.all(
        blogPageWithSize.map(async (blog) => {
          if (blog?.coverPhoto?.startsWith("https://amarant2.s3") || blog?.coverPhoto?.startsWith("https://amarant-rpa.s3") || blog?.coverPhoto?.startsWith("https://amarant-docs.s3")) {
            const url = await getPresignedUrlView(blog.coverPhoto);
            newUrls[blog._id] = url;
          } else {
            newUrls[blog._id] = blog.coverPhoto;
          }
        })
      );
      if (isMounted) setPresignedUrls(newUrls);
    };
    if (blogPageWithSize.length > 0) fetchPresignedUrls();
    return () => {
      isMounted = false; // Cleanup function
    };
  }, [blogPageWithSize]); // Runs when `blogPageWithSize` changes

  return (
    <div className="news-container">
      {blogPageWithSize.map((blog, i) => {
        return (
          <div
            className="vertical-news"
            style={{
              backgroundImage: presignedUrls[blog._id] ? `url(${presignedUrls[blog._id]})` : "none",
            }}
            key={i}
          >
            <div className={`upper-vertical-news ${i % 2 === 0 && "blue"}`}>
              <h4 className={`title-news ${i % 2 === 1 && "blue"}`} style={{ textTransform: "uppercase" }}>
                {blog.title}
              </h4>
              <Link to={`/article/${blog._id}`} className={`read-more ${i % 2 === 1 && "blue"}`} style={{ opacity: window.innerWidth < 900 && "1" }}>
                <div style={{ display: "flex" }}>
                  Прочети повече <div className={`arrowTab ${i % 2 === 1 ? "selectedBlue" : "selected"}`} style={{ height: "31px", marginLeft: window.innerWidth > 900 ? "8%" : "4%", marginTop: window.innerWidth > 900 ? "0.3%" : window.innerWidth < 900 && window.innerWidth > 760 ? "-0.4%" : "0%" }}></div>
                </div>
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
}
